.single_post {
  width: 100%;
}

.post_container {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  justify-content: space-between;
}

.side_wire {
  width: 5px;
  height: calc(100% - 16px);
  position: absolute;
  border-left: 1px solid var(--primary-color);
}

.bottom_wire {
  flex-grow: 1;
  border-bottom: 1px solid var(--primary-color);
}

.container_img_post {
  width: 130px;
  height: 130px;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 20px;
}

.thumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.post_title {
  flex-grow: 1;
  width: calc(100% - (130px * 1/2));
  padding-left: 20px ;
  padding-right: calc((130px * 1/2) + 20px);
  padding-top: 20px;
  padding-bottom: 20px;
  border-right: 1px var(--primary-color) solid;
  border-top: 1px var(--primary-color) solid ;
}

.sub_title {
  margin-bottom: 0;
}

.container_post_date {
  width: 100%;
  display: flex;
  width: calc(100% - (130px * 1/2));
  gap: 10px;
  position: absolute;
  bottom: 0;
}

.post_date {
  text-transform: capitalize;
  color: var(--primary-color);
  transform: translate(-4px, 6px);
  font-weight: 400;
  font-size: 1.4em;
}

.post_date strong {
  font-weight: 900;
  font-size: 15px;
  margin-right: 10px;
}