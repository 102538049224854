@import url('https://fonts.googleapis.com/css2?family=Jost:wght@300;400;900&family=Lato:ital,wght@0,400;0,700;0,900;1,400;1,700;1,900&family=Yeseva+One&display=swap');
:root {
  --primary-color: #4b4a4a;
  --bg_color: #f6f6f6;

  --secundary-color-light: #cb4f63;
  --secundary-color-dark: #2f0225;
  
  font-family: 'Lato', sans-serif;
}

body {
  background: var(--bg_color);
	/* background: -moz-linear-gradient(45deg, var(--secundary-color-light) 0%, var(--secundary-color-dark) 80%);
	background: -webkit-linear-gradient(45deg, var(--secundary-color-light) 0%, var(--secundary-color-dark) 80%);
	background: linear-gradient(45deg, var(--secundary-color-light) 0%, var(--secundary-color-dark) 80%); */
  min-height: 100vh;
  width: 100vw;
  max-width: 100%;
}

h2, .post_title {
  text-align: center;
  font-family: 'Yeseva One', cursive;
  padding: 0 0 40px 0;
  font-size: 4em;
  line-height: 1em;
  font-weight: 400;
  color: var(--primary-color);
}

.post_title {
  padding: 0 0 20px 0;
}

h3 {
  text-transform: uppercase;
  line-height: 1.4em;
  font-weight: 800;
  padding-bottom: 10px;
  font-size: 1.4em;
  color: var(--primary-color);
}

p , li {
  font-size: 1.5em;
  line-height: 1.6em;
  color: var(--primary-color);
  margin-bottom: 1.5em;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.container_main {
  min-height: 100vh;
  width: 100vw;
  max-width: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.container_post_carousel {
  background-color: black;
}

.container_section, .container_post_carousel, .container_home {
  width: 100%;
  margin: auto;
  padding: 80px 5vw 40px 5vw;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
}

.container_home {
  padding: 40px 5vw 40px 5vw;
}

.container_list_posts {
  width: 100%;
  margin: auto;
  display: grid;
	grid-template-columns: 1fr;
	grid-auto-rows: 170px;
	grid-gap: 30px;
  flex-grow: 1;
  position: relative;
  padding-bottom: 90px;
}


.container_videos {
  width: 100%;
  margin: auto;
  display: grid;
	grid-template-columns: 1fr;
	grid-auto-rows: auto;
	grid-gap: 30px;
  flex-grow: 1;
  position: relative;
  padding-bottom: 90px;
}

.dependencias_lista li {
  list-style: disc;
  margin-left: 20px;
}
.loading {
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2em;
}

@media screen and (min-width: 750px) {
  .container_list_posts {
    width: 100%;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-auto-rows: 170px;
    grid-gap: 30px;
  }
  .container_videos {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (min-width: 900px) {
  .container_section {
    width: 60vw;
    padding: 90px 0 40px 0;
  }

  .container_home {
    width: 60vw;
  }

  .container_list_posts {
    width: 100%;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    grid-auto-rows: 170px;
    grid-gap: 30px;
  }

  .container_videos {
    grid-template-columns: 1fr 1fr 1fr;
  }
}