.container_footer {
  background-color: black;
  color: white;
  padding: 20px;
}

.container_social {
  display: flex;
  width: 100%;
  gap: 40px;
  justify-content: center;
  padding: 10px 0;
}

.container_footer_description {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.container_footer_description p {
  color: white;
  text-align: center;
  font-size: 1.4em;
  margin-bottom: 0;
}

/* @media screen and (min-width: 750px) {
  .container_footer_description {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
} */

@media screen and (min-width: 900px) {
  .container_footer_description {
    flex-direction: column;
    gap: 5px;
  }
}