.video {
  /* background: olive; */
  display: flex;
  flex-direction: column;
}

.player_video {
  width: 100%;
  height: calc(9 / 16 * 90vw);
  border: solid 1px black;
  background-color: black;
}

.containar_video, .thumbnail_container{
  width: 100%;
  height: 100%;
  position: relative;
}

.play_button{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;
  border: none;
  background: url(../../img/icon/play_button.svg) center / contain no-repeat;
}

.data_video {
  /* background-color: tomato; */
  width: 100%;
  border-left: solid 1px black;
  border-right: solid 1px black;
  padding-bottom: 10px;
  position: relative;
  flex-grow: 1;
}

.data_video > h3 {
  margin-bottom: 10px;
  background-color: black;
  color: white;
  padding: 30px 20px;
  margin-bottom: 20px;
  font-weight: 300;
  min-height: 100px;
}

.data_video > p{
  padding: 0 20px;
}

.container_post_date {
  width: 100%;
  display: flex;
  gap: 10px;
  /* position: absolute; */
  bottom: 0;
  border-right: 1px solid black;
}

.post_date {
  text-transform: capitalize;
  color: var(--primary-color);
  transform: translate(-4px, 6px);
  font-weight: 400;
  font-size: 1.4em;
}

.post_date strong {
  font-weight: 900;
  font-size: 15px;
  margin-right: 10px;
}

.bottom_wire {
  flex-grow: 1;
  border-bottom: 1px solid var(--primary-color);
}

@media screen and (min-width: 750px) {

  .player_video {
    height: calc(9 / 16 * ((90vw - 30px) * 1 / 2 ));
  }

}

@media screen and (min-width: 900px) {
  .player_video {
    height: calc(9 / 16 * ((90vw - 60px) * 1 / 3 ));
  }
}