.next_post, .prev_post {
  background-color: black;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40vw;
  color: var(--bg_color);
  text-transform: uppercase;
  font-size: 1.4em;
  position: fixed;
  writing-mode: tb-rl;
  top: 50vw;
  letter-spacing: 0.03em;
}

.next_post strong, .prev_post strong {
  font-weight: 900;
  margin-bottom: 0.5em;
  /* color: tomato; */
}

.prev_post {
  left: 0px;
  transform: rotate(0deg);
}

.next_post {
  right: 0px;
  transform: rotate(-180deg);
}

@media screen and (min-width: 750px) {
  .next_post, .prev_post {
    height: 300px;
    top: 50vh;
    transform: translateY(-50%);
  }
}