/* Hamburger icon */
.hamburger {
  width: 30px;
  height: 30px;
  position: relative;
  z-index: 10000;
  cursor: pointer;
}

.hamburger_animacao {
  width: 25px;
  height: 2px;
  border-radius: 2px;
  background-color: white;
  transition: ease-in-out 0.5s;
  position: absolute;
  top: 50%;
  right: 0;
}

.hamburger_animacao::before, .hamburger_animacao::after{
  content: "";
  width: 30px;
  height: 2px;
  position: absolute;
  border-radius: 2px;
  right: 0;
  background-color: white;
  transition: ease-in-out 0.5s;
}

.hamburger_animacao::before {
  top: 10px;
}

.hamburger_animacao::after {
  top: -10px;
}

.hamburger.open > .hamburger_animacao {
  background-color: rgb(0, 0, 0);
  width: 3px;
}

.hamburger.open > .hamburger_animacao::before{
  transform: rotate(-45deg);
  transform: rotate(45deg) translate(-8.67px, -5.67px);
}

.hamburger.open > .hamburger_animacao::after{
  transform: rotate(-45deg) translate(-7.67px, 5.67px);
} 

/* Header container */
.container_header {
  background-color: black;
  color: white;
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10vw;
  position: fixed;
  z-index: 999;
}

.logo {
  /* width: 80px; */
  /* height: 2.5em; */
  color: white;
  z-index: 999;
  font-size: 2.5em;
  font-family: 'Jost', sans-serif;
  font-weight: 300;
}

.logo span {
  font-weight: 900;
}


/* Close menu button */
.close_menu {
  font-size: 13px;
  /* font-weight: 900; */
  text-transform: uppercase;
  position: absolute;
  top: 23px;
  right: 47;
  transition: ease-in-out 0.5s;
  color: black;
}

.container_nav.open .close_menu {
  color: white;
  right: calc(10vw + 40px);
}

/* Navigation container */
.container_nav {
  background-color: rgb(0, 0, 0);
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  right: 0;
  top: 0;
  transform: translateY(-100vh);
  padding: 80px 20vw 80px 20vw;
  gap: 5em;
  overflow: hidden;
  transition: transform ease-in-out 0s;
}

.container_nav.open {
  transform: translateY(0%);
}

.container_nav > a {
  color: rgb(108, 107, 107);
  font-family: 'Yeseva One', cursive;
  font-size: 4.4em;
  position: relative;
  transform: translateX(-100%);
  transition-timing-function: cubic-bezier(0,0,.2,1);
}

.container_nav.open > a:nth-of-type(1){
  transform: translateX(0%);
  transition-duration: 0.3s;
}

.container_nav.open > a:nth-of-type(2){
  transform: translateX(0%);
  transition-duration: 0.6s;
}

.container_nav.open > a:nth-of-type(3){
  transform: translateX(0%);
  transition-duration: 0.9s;
}

.container_nav.open > a:nth-of-type(4){
  transform: translateX(0%);
  transition-duration: 2s;
}
.container_nav > a[class="active"]{
 color: white;
}