.container_single_post {
  position: relative;
  border: solid 1px var(--primary-color);
  z-index: 2;
}

.container_main_image{
  width: 100%;
  height: 90vw;
  overflow: hidden;
  margin-bottom: 20px;
}

.post_subtitle {
  font-weight: 900;
  text-align: center;
  width: 100%;
  padding: 0 0 20px 0 ;
}

.container_post {
  padding: 0 20px;
  margin: 0 0 40px 0;
}

.container_post ol li {
  list-style: auto;
  margin-left: 20px;
  padding-left: 15px;
  padding-right: 20px;
}

.container_post strong {
  text-transform: uppercase;
  font-weight: 900;
  font-size: 0.9em;
  letter-spacing: 0.05em;
}


.footer_container_author {
  display: flex ;
  /* height: 50px; */
  justify-content: center;
  gap: 20px;
  align-items: center;
  /* margin: 20px 0; */
  padding: 20px;
  border-top: 1px solid var(--primary-color);
}

.container_title {
  padding: 0 20px;
}

.footer_container_author p {
 margin-bottom: 0;
}

.author_avatar{
  width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 100%;
}

@media screen and (min-width: 750px) {
  .container_main_image{
    height: 400px;
  }
}

@media screen and (min-width: 900px) {
  .container_main_image{
    width: 60vw;
    transform: translate(0, 0);
  }

  /* .container_post {
    padding: 0 20px;
    margin-bottom: 40px;
    width: 40vw;
    margin: 0 auto 80px auto;
  } */
}